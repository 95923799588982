body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.marker {
  transform: translate(-50%, -100%);
}

.error {
  color: #f05305;
}

.marker.red path {
  fill: #f05305;
}

.marker.yellow path {
  fill: #F3B73E;
}

.popup {
  width: 40vw;
  padding: 1rem;
}

.entry-form label,
.entry-form input,
.entry-form textarea,
.entry-form button {
  font-size: 1rem;
  margin: 0.5rem 0;
  display: block;
  width: 100%;
}

/* App.css */

.marker-container {
  cursor: pointer;
}

.marker.yellow {
  fill: yellow;
}

.marker.red {
  fill: red;
}

.custom-popup {
  max-width: 300px;
  font-size: 14px;
}

.popup-content {
  padding: 10px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.popup-title {
  font-size: 16px;
  margin: 0 0 5px;
}

.popup-comments {
  margin: 0 0 5px;
}

.popup-date {
  color: gray;
  font-size: 12px;
}

.popup-image {
  max-width: 100%;
  border-radius: 5px;
  margin-top: 10px;
}
